@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped&display=swap');

body {
  font-family: "Architects Daughter", sans-serif;
  background-color: #ffffff;
  background-image: url("https://transparenttextures.com/patterns/debut-light.png");
}

h2 {
  font-size: 1.5rem;
}


.add-button {
  color: grey;
  padding: 20px;
  text-align: center;
}

.customFont {
  font-family: "Architects Daughter", sans-serif;
  font-size: 1.7rem;
}

.close-btn {
  background-color: rgba(0,0,0,0);
  border:none;
  cursor: pointer;
  margin-left: auto;
  text-align: left;
  font-size: 0.2rem;
}

.close-btn:hover {
  color: red;
  transition: 0.2s;
  transition-timing-function: ease;
}

.hidden-column {
  color: rgba(0,0,0,0);
  font-size: 0.95rem;
  user-select: none;
}

.shown-column {
  font-size: 0.95rem;
  user-select: none;
}

.start-date {
  padding-top: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.context-wrapper{
  display: flex;
  justify-content: center;
  height: 100%;
  margin-left: 10px;
  max-width: 98%;
  padding-bottom: 20px;
}

.column-wrap{
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  min-width: 200px;
}

.column-wrap:first-child {
  padding-top: 23px;
}

.column-wrap h2 {
  display: none;
}

.column-wrap:first-child h2{
  display: inline;
}

.dropbox{
  border-radius: 10px;
  border-color:red;
  margin-right: -1px;
  min-height: 850px;
  padding: 8px;
  /* width: 420px; */
}

.dropbox:first-child {
  background-color:"rgb(214, 214, 214)";
  border-radius: 10px;
}


.dragbox{
  border: 1px solid;
  border-color: rgb(224, 224, 224);
  border-radius: 10px;
  user-select: none;
  padding: 5px 10px;
  margin: 0 0 10px 0;
  color: #111;
  min-height: 10px;
}

.dragbox:last-child {
  margin-bottom: 0;
}

.card-container {
  display: grid;
  line-height: normal;
  grid-template-columns: 1.5fr 1.5fr 1.5fr;
  vertical-align: middle;
}

.card-body {
  align-items:center;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 10px;  
}

.card-name{
  font-size: 1.2rem;
  font-weight: 1000;
}

.card-shock {
  line-height: 20px;
  padding-left: 10px;
  padding-top: 10%;
  vertical-align: center;
}

.card-left {
  font-size: 1.2rem;
  padding-top: 7%;
  padding-left: 5px;
  width: 120px;
}

.card-right {
  font-weight: 1000;
  line-height: normal;
  
}

.injury-days {
  background-image: url("circle-mark.png");
  background-size:contain;
  height: 80px;
  text-align: center;
  width: 80px;
  display:table;
}

.injury-days span {
  display: inline-block;
  font-size: 1.5rem;
  line-height: normal;
  padding-bottom: 3px;
  vertical-align: middle;
  text-align: center;
  display:table-cell;
  vertical-align:middle;
}

.injuries {
  display: flex;
  align-items: center;
  font-size: 1.0rem;
  text-align: left;
}

.injuries ul {
  margin: 0;
  padding: 0;
}

.injuries li {
  list-style-type: none;
  padding: 0 2px;
}

.shock {
  cursor: pointer;
  display: inline;
  vertical-align: middle;
}

.shock-edit {
  cursor: pointer;
  display: inline;
  font-size: 1.3rem;
  grid-template-columns: 1fr 1fr;
}

.shock-value-edit {
  background-color: rgb(248, 248, 248);
  border: 2px red;
  border-radius: 10%;
  margin-left: 10px;
  width: 50px;
}


@media screen and (max-width: 1500px), only screen and (max-width: 1200px) {

  h2 {
    font-size: 0.8rem;
  }

  .column-wrap{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 23px;
    min-width: 122px;
  }
  
  .column-wrap:first-child{
    padding-top: 0;
  }

  .card-container {
    grid-template-columns: 1fr;
  }

  .card-left {
    text-align: center;
    padding-left: 0;
    width: 80px;
  }

  .card-right {
    padding-left: 0;
  }

  .card-name {
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-shock {
    display: none;
  }

  .dragbox {
    line-height: 10px;
    padding-bottom: 0;
    padding-top: 0;
    width: auto;
  }

  .injuries {
    align-items: center;
    display: flex;   
    font-size: 0.6rem;
    margin: auto;
    padding-top: 10%;
    text-align: center;
    width: auto;
  }

  .injuries li:nth-of-type(1n+2) {
    display: none;
}

  .injury-days {
    background-image: none;
    /* background-size:contain; */
    line-height: 50px;
    margin: auto;
    max-height: 50px;
    max-width: 50px; 
    text-align: center;
    width: auto;
  }

  .injury-days span {
    font-size: 1rem; 
  }



}
